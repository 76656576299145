<template>
  <a-form-model
    :model="form"
    ref="form"
    hideRequiredMark
    validateOnRuleChange
    :rules="rules"
  >
    <a-form-model-item prop="mark" label="文件备注名称">
      <a-input
        v-model="form.mark"
        placeholder="请输入备注名称，限1000字符长度"
        :maxLength="1000"
      />
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component()
export default class MarkEdit extends Vue {
  @Prop() mark;

  mounted() {
    this.form.mark = this.mark;
  }

  form = {
    mark: '',
  };
  rules = {
    mark: [
      {
        required: true,
        message: '备注不能为空',
      },
    ],
  };
  async getValue() {
    await this.$refs.form.validate();
    return this.form.mark;
  }
}
</script>
<style lang="less" module></style>
